import { UseMutationResult } from '@tanstack/react-query';
import { Button, Form, FormInstance, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import PhoneItemFooter from './PhoneItemFooter';

interface IContactFormProps {
  form: FormInstance<any>;
  createContactForm: UseMutationResult<void, any, any, unknown>;
}

const ContactForm = (props: IContactFormProps) => {
  const { form, createContactForm } = props;

  return (
    <Form
      layout='vertical'
      form={form}
      onFinish={vals => createContactForm.mutate(vals)}
      className='flex w-full max-w-[630px] flex-1 flex-col gap-6'
    >
      <div className='flex w-full items-center font-[hneu] text-[20px] !leading-[24px] text-zelene-blue'>
        <div className='h-1 w-[93px] bg-[#1D3F75]'></div>
        <span className='pl-2 text-[20px] font-medium'>Contact Form</span>
      </div>

      <div className='flex w-full flex-col gap-3 *:flex-1 *:!rounded-xl sm:flex-row'>
        <FormItem name={'name'} rules={[{ required: true, message: 'Please enter your  name' }]}>
          <Input size='large' placeholder='Name' />
        </FormItem>
        <FormItem
          name={'email'}
          rules={[
            { required: true, message: 'Please enter your email' },
            { type: 'email', message: 'Please enter valid email' },
          ]}
        >
          <Input size='large' placeholder='Email' />
        </FormItem>
      </div>

      <div className='flex flex-col gap-3 *:flex-1 *:!rounded-xl sm:flex-row'>
        <PhoneItemFooter size={'large'} form={form} />

        <FormItem name={'description'}>
          <Input size='large' placeholder='Message' />
        </FormItem>
      </div>

      <Button
        size='large'
        onClick={() => form.submit()}
        type='primary'
        className='!rounded-xl !bg-[#224b8b] hover:!bg-[#4674C6]'
      >
        Send
      </Button>
    </Form>
  );
};

export default ContactForm;
