'use client';

import axiosClient from '@/helper/call-center';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useState } from 'react';

const FloatingAction = () => {
  const [hidden, setHidden] = useState(true);
  const location = typeof window !== 'undefined' ? localStorage.getItem('location_id') : null;

  const userQuery = useQuery({
    queryKey: ['location-detail'],
    queryFn: async () => {
      try {
        const result: any = await axiosClient.get(`store-branch/${location}`);

        return result;
      } catch (error) {
        return [];
      }
    },
    refetchOnWindowFocus: false,
  });

  const telByShop: any = userQuery?.data?.data?.phone;

  return (
    <div className='group fixed bottom-0 right-0 flex h-24 w-24 items-end justify-end p-2'>
      <div
        onClick={prev => setHidden(!hidden)}
        className='absolute z-50 flex cursor-pointer items-center justify-center rounded-full bg-gradient-to-r from-[#102647] to-[#1D3F75] p-3 text-white shadow-xl'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className={clsx('lucide lucide-plus h-6 w-6 transition-all duration-[0.6s]', hidden && 'rotate-45')}
        >
          <path d='M5 12h14' />
          <path d='M12 5v14' />
        </svg>
        <path strokeLinecap='round' strokeLinejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' />
      </div>

      <a
        href={
          'https://www.facebook.com/zelenespa/?paipv=0&eav=AfZgmYGmrAtqDCLUfx2nBNq1Zjm06cyWqFvrW66BpLROVpZZ2JJlV4kF1DZhQixu9NM&_rdr'
        }
        target='_blank'
        rel='noopener noreferrer'
        // className='absolute flex scale-100 scale-y-0 cursor-pointer rounded-full bg-[#007BF7] p-2 text-white transition-all duration-[0.2s] ease-out hover:p-3 hover:text-white group-hover:-translate-x-16 group-hover:scale-y-100'
        className={clsx(
          'absolute flex scale-100 scale-y-0 cursor-pointer rounded-full bg-[#007BF7] p-2 text-white transition-all duration-[0.2s] ease-out hover:p-3 hover:text-white',
          hidden && '-translate-x-16 scale-y-100',
        )}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='lucide lucide-facebook'
        >
          <path d='M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z' />
        </svg>
      </a>

      <a
        href={`tel:${telByShop}`}
        target='_blank'
        rel='noopener noreferrer'
        // className='absolute flex scale-x-0 cursor-pointer rounded-full bg-[#D29A2A] p-2 text-white transition-all duration-[0.2s] ease-out hover:p-3 hover:text-white group-hover:-translate-x-14 group-hover:-translate-y-14 group-hover:scale-x-100'
        className={clsx(
          'absolute flex scale-x-0 cursor-pointer rounded-full bg-[#D29A2A] p-2 text-white transition-all duration-[0.2s] ease-out hover:p-3 hover:text-white',
          hidden && '-translate-x-14 -translate-y-14 scale-x-100',
        )}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='lucide lucide-phone'
        >
          <path d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z' />
        </svg>
      </a>

      <a
        href={'https://www.instagram.com/zelenespa/'}
        target='_blank'
        rel='noopener noreferrer'
        // className='absolute flex scale-x-0 cursor-pointer rounded-full bg-gradient-to-r from-[#833AB4] via-[#FD1D1D] to-[#FCB045] p-2 text-white transition-all duration-[0.2s] ease-out hover:p-3 hover:text-white group-hover:-translate-y-16 group-hover:scale-x-100'
        className={clsx(
          'absolute flex scale-x-0 cursor-pointer rounded-full bg-gradient-to-r from-[#833AB4] via-[#FD1D1D] to-[#FCB045] p-2 text-white transition-all duration-[0.2s] ease-out hover:p-3 hover:text-white',
          hidden && '-translate-y-16 scale-x-100',
        )}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='lucide lucide-instagram'
        >
          <rect width='20' height='20' x='2' y='2' rx='5' ry='5' />
          <path d='M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z' />
          <line x1='17.5' x2='17.51' y1='6.5' y2='6.5' />
        </svg>
      </a>
    </div>
  );
};

export default FloatingAction;
