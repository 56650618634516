'use client';

import { useRouter } from '@/hooks/useRouter';
import { Carousel } from 'antd';

const items = [
  {
    title: 'ESSENTIAL',
    price: 'from $99',
    bgClass: 'bg-product-card1',
  },
  {
    title: 'PREMIUM',
    price: 'from $149',
    bgClass: 'bg-product-card2',
  },
  {
    title: 'ELITE',
    price: 'from $199',
    bgClass: 'bg-product-card3',
  },
  // Add more items as needed
];

const MembershipCarousel = () => {
  const router = useRouter();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true, // Enable dragging
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 1.2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div className='mx-auto mt-6 w-full sm:w-[70%]'>
      <Carousel {...settings}>
        {items.map((item, index) => (
          <div key={index} className='flex justify-center' 
          onClick={() => router.push('')}>
            <div className='flex flex-col items-center justify-center gap-2'>
              <div
                className={`relative h-[500px] w-[334px] rounded-3xl !border-2 !border-[#FCDCAB] bg-slate-200 ${item.bgClass} cursor-pointer bg-cover bg-center bg-no-repeat`}
              >
                {item.title === 'ELITE' && <div className='relative -right-0 -top-12 size-20 '></div>}
              </div>
              <span className='pt-2 font-hneu text-[18px] font-medium leading-[21px] text-white [text-shadow:0px_4px_14px_rgba(0,_0,_0,_0.20)]'>
                {item.title}
              </span>
              <span className='pt-2 font-hneu text-base font-normal leading-[21px] text-white [text-shadow:0px_4px_14px_rgba(0,_0,_0,_0.20)]'>
                {item.price}
              </span>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MembershipCarousel;
